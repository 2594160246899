import { Circle as CircleStyle, Fill, Icon, Stroke, Style } from 'ol/style'
import VectorImage from 'ol/layer/VectorImage'

const activeFill = new Fill({
    color: 'rgb(50, 115, 220)',
})

const activeStroke = new Stroke({
    color: '#ffffff',
    width: 1,
})

const inactiveFill = new Fill({
    color: '#9C9C9C',
})

// const inactiveStroke = new Stroke({
//     color: 'rgb(0, 100, 255)',
//     width: 1,
// })

const activeStyle = { stroke: activeStroke, fill: activeFill }
const inactiveStyle = { fill: inactiveFill }
const styles = {
    1: {
        1: new Style({
            image: new CircleStyle({
                radius: 3,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        2: new Style({
            image: new CircleStyle({
                radius: 4,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        3: new Style({
            image: new CircleStyle({
                radius: 5,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        4: new Style({
            image: new CircleStyle({
                radius: 6,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        5: new Style({
            image: new CircleStyle({
                radius: 7,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        6: new Style({
            image: new CircleStyle({
                radius: 8,
                ...activeStyle,
            }),
            zIndex: 10
        }),
        7: new Style({
            image: new CircleStyle({
                radius: 9,
                ...activeStyle,
            }),
            zIndex: 10
        }),
    },
    0: {
        1: new Style({
            image: new CircleStyle({
                radius: 1,
                ...inactiveStyle,
            }),
        }),
        2: new Style({
            image: new CircleStyle({
                radius: 2,
                ...inactiveStyle,
            }),
        }),
        3: new Style({
            image: new CircleStyle({
                radius: 3,
                ...inactiveStyle,
            }),
        }),
        4: new Style({
            image: new CircleStyle({
                radius: 4,
                ...inactiveStyle,
            }),
        }),
        5: new Style({
            image: new CircleStyle({
                radius: 5,
                ...inactiveStyle,
            }),
        }),
        6: new Style({
            image: new CircleStyle({
                radius: 6,
                ...inactiveStyle,
            }),
        }),
        7: new Style({
            image: new CircleStyle({
                radius: 7,
                ...inactiveStyle,
            }),
        }),
    },
    invisible: new Style({
        image: new CircleStyle({
            stroke: null,
            fill: null,
        }),
    }),
    highlighted: new Style({
        image: new CircleStyle({
            stroke: new Stroke({ color: 'rgb(100, 80, 210)', width: 2 }),
            fill: new Fill({ color: 'rgb(210, 80, 210)' }),
            radius: 5,
        }),
        zIndex: 12,
    }),
}

const drawStyles = {
    polygonStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)', width: 2}),
        fill: new Fill({color: 'rgba(235, 162, 80, 0.3)'}),
    }),
    pointerStylesHighlighted: new Style ({
        image: new CircleStyle({
            ...activeStyle,
            radius: 7,
        })
    }),
    pointerStylesSelected: new Style ({
        image: new CircleStyle({
            ...inactiveStyle,
            radius: 7,
        })
    }),
    boundingBoxStyles: new Style({
        stroke: new Stroke({color: 'rgb(235, 162, 80)'}),
    }),
}

const agencies = [
    {code: 'CBM', label: 'CBM', color: '#006100'}, 
    {code: 'NWIS', label: 'NWIS', color: '#559100'}, 
    {code: 'STP', label: 'STP', color: '#E6E600'},
    {code: 'EDD', label: 'EDD', color: '#FFAA00'},
    {code: 'GEM', label: 'GEM', color: '#FF5500'}
]

const facilityIcons = [
    { code: 'Facility Centroid', label: 'Facility Location', icon: 'genderless'},
    { code: 'GW', label: 'Groundwater Sample', icon: 'water'},
    { code: 'Injection Well', label: 'Injection Well', icon: 'caret-up'},
    { code: 'Monitor Well', label: 'Monitor Well', icon: 'adjust'},
    { code: 'NA', label: 'Unknown', icon: 'asterisk'},
    { code: 'Pond or Surge Pond', label: 'Pond or Surge Pond', icon: 'hockey-puck'},
    { code: 'Production Well', label: 'Production Well', icon: 'circle'},
    { code: 'River/Creek Downstream', label: 'River/Creek Downstream', icon: 'arrow-up'},
    { code: 'River/Creek Upstream', label: 'River/Creek Upstream', icon: 'arrow-down'},
    { code: 'Septic Tank', label: 'Septic Tank', icon: 'filter'},
    { code: 'SW', label: 'Surface Water Sample', icon: 'burn'},
    { code: 'Visual', label: 'Visual', icon: 'stop-circle'},
    { code: 'Water Supply Well', label: 'Water Supply Well', icon: 'star'},
    { code: 'WEL', label: 'Well', icon: 'tint'},
    { code: 'COWDF', label: 'Commercial Oilfield Wastewater Disposal Facility', icon: 'times'},
    { code: 'CAFO', label: 'Confined Animal Feedlot Operation', icon: 'stop'},
]

const facilityLegendStyles = [...facilityIcons.map(x => ({fillColor: `${x.icon}-alt.svg`, range: x.label})), ...agencies.map(x => ({fillColor: x.color, range: x.label}))]

const sizes = [1,2,3,4,5,6,7]
const facilityStyles = agencies.reduce((acc, curr) => {
    const {code: agencyCode, color} = curr
    const symbols = facilityIcons.reduce((symbolOptions, facility) => {
        const { code: facilityCode, icon } = facility
        const options = sizes.reduce((sizeOptions, size) => {
            const imgSize = 512 // the icons are size 512
            const s = ( size + 1 ) * 15 / imgSize
            return {
                ...sizeOptions,
                [size]: new Style({
                    image: new Icon({
                        src: `/${icon}.svg`,
                        scale: s,
                        color,
                        anchor: [0.5, 0.5],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                    }),
                })
            }
        }, {})
        return {
            ...symbolOptions,
            [facilityCode]: options
        }
    }, {})
    return {
        ...acc,
        [agencyCode]: symbols
    }
}, {})

const unthemedStyles = sizes.reduce((acc, curr) => {
    return {
        ...acc,
        [curr]: new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'grey'
                }),
                radius: curr
            })
        })
    }
}, {})

const pressureLegends = [
    {
        fillColor: '#006100',
        range: 'Flow'
    },
    {
        fillColor: '#FF0000',
        range: 'Pressure'
    },
    {
        fillColor: '#0000FF',
        range: 'Pressure and Flow'
    },
    {
        fillColor: '#808080',
        range: 'No Pressure-Volume data'
    }
]

const pvStyles = pressureLegends.reduce((symbolOptions, facility) => {
        const { range: code, fillColor: color } = facility
        const options = sizes.reduce((sizeOptions, size) => {
            return {
                ...sizeOptions,
                [size]: new Style({
                    image: new CircleStyle({
                        fill: new Fill({
                            color
                        }),
                        radius: size
                    })
                })
            }
        }, {})
        return {
            ...symbolOptions,
            [code]: options
        }
    }, {})

    const getBaseDataLayer = (dataLayerName, getStyleFunction, source) => {
        // add the themed facility layer
        const baseDataLayer = new VectorImage({
            source,
            visible: true,
            style: getStyleFunction,
            minResolution: 0,
            zIndex: 100,
        })
        baseDataLayer.set('name', dataLayerName)
        return baseDataLayer
    }
    
    const getLayerLegendObject = (dataLayerName, legendStyles, groupName) => ({
        [dataLayerName]: {
            display: false,
            expanded: false,
            unique: true,
            LayerName: dataLayerName,
            layerGroupName: groupName,
            loaded: true,
            // shape: 'circle',
            StyleArray: JSON.stringify(legendStyles),
            LayerType: 'THEME'
        }
    })
    
const styleFunction = function(theme, feature, zoomLevel) {
    const selected = feature.get('selected')
    const displayed = feature.get('displayed')
    const highlighted = feature.get('highlighted')
    if (displayed) {
        if (highlighted) {
            return styles['highlighted']
        }
        const size = Math.ceil(zoomLevel)
        if (selected) {
            return styles[selected][size]
        } else {
            if (theme === 'samplingPointType') {
                const agency = feature.get('Agency')
                const facilityType = feature.get('FacilityType')
                const style = facilityStyles[agency][facilityType][size]
                return style
            } else if (theme === 'samplingPointPressure') {
                const hasFlowData = feature.get('HasFlowData')
                const hasPressureData = feature.get('HasPressureData')

                if (hasFlowData && !hasPressureData) {
                    return pvStyles[`Flow`][size]
                }
                else if (!hasFlowData && hasPressureData) { 
                    return pvStyles[`Pressure`][size]
                }
                else if (hasFlowData && hasPressureData) {
                    return pvStyles[`Pressure and Flow`][size]
                }
                else if (!hasFlowData && !hasPressureData) {
                    return pvStyles[`No Pressure-Volume data`][size]
                }
            } 
            else {
                return unthemedStyles[size]
            }
        }
    }
    return styles.invisible[0]
}

export { drawStyles, facilityLegendStyles, pressureLegends, getLayerLegendObject, getBaseDataLayer }
export default styleFunction
