import React, { useContext, useEffect, useMemo, useState } from 'react'

import EditableTable from '../../../elem/table/EditableTable'
import { parseUploadColumns } from '../../../../utils/table/parseColumns'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'
import GeneralErrors from './GeneralErrors'
import deepClone from 'lodash.clonedeep'
import PageErrorText from './PageErrorText'
import { useFormContext } from 'react-hook-form'


const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = config.ColumnName
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const removeAndShiftRemaining = (data, rowIdx) => {
    // keep the first elements of the array the same
    const keep = data.slice(0, rowIdx)
    // shift the elements after the deleted row into the other places
    const remaining = data.slice(rowIdx)
    remaining.shift()
    return [...keep, ...remaining]
}

const deleteFunction = (
    setData,
    formMethods,
    accessor,
    setFormDirty,
    rowIdx
) => {

    // unregister the last row and set values in react-hook-form to new values
    const formValues = formMethods.getValues({ nest: true })
    const associatedValues = formValues[accessor]
    associatedValues.map((entry, idx) => {
        if (idx >= rowIdx && idx < associatedValues.length - 1) {
            const newValue = associatedValues[idx + 1]
            const fieldToUpdate = `${accessor}[${idx}]`
            Object.keys(entry).map(key => {
                const value = newValue[key]
                // const value = get(newValue, key)
                return formMethods.setValue(
                    `${fieldToUpdate}.${key}`,
                    value ? value : null
                )
            })
        }
        return null
    })

    const lastRowIdx = formValues[accessor].length - 1
    // formMethods.setValue(`${accessor}`, removeAndShiftRemaining(associatedValues, rowIdx))
    const lastRow = formValues[accessor][lastRowIdx]
    // unregister the fields associated with the last row
    Object.keys(lastRow).map(key => {
        const fieldName = `${accessor}[${lastRowIdx}].${key}`
        formMethods.setValue(fieldName, null)
        formMethods.unregister(`${accessor}[${lastRowIdx}].${key}`)
        return null
    })

    // if this is the last row, register a flag to denote this for data update
    if (lastRowIdx === 0) {
        formMethods.register(`${accessor}`)
        formMethods.setValue(`${accessor}`, 'reset')
    }
        // update the data associated with the table
        setData(existingData => {
            const newData = removeAndShiftRemaining(existingData, rowIdx)
            return newData
        })
    setFormDirty(true)
}

const getDataFromSubmissionState = (submissionState, accessor) =>
    submissionState && submissionState[accessor]
        ? submissionState[accessor]
        : []

export default ({ config, accessor, title, subtitle }) => {
    const {
        submissionState,
        formMethods,
        viewOnly,
        setTableData,
        setFormDirty,
        updateControlledPageSize,
        pageErrorText,
        beforePageChange
    } = useContext(DataContext)

    const { setValue } = useFormContext()

    const [data, setData] = useState(getDataFromSubmissionState(submissionState, accessor))

    const columns = useMemo(
        () =>
            parseUploadColumns(
                [],
                config,
                deleteFunction.bind(
                    this,
                    setData,
                    formMethods,
                    accessor,
                    setFormDirty
                ),
                submissionState,
                viewOnly
            ),
        [config, formMethods, accessor, submissionState, viewOnly]
    )

    useEffect(() => {
        setData(getDataFromSubmissionState(submissionState, accessor))
    }, [config, submissionState])

    useEffect(() => {
        if (data.length) {
            const lastRowIdx = data.length - 1
            const lastRow = data[lastRowIdx]
            if (!Object.keys(lastRow).some(x => lastRow[x] !== null)) {
                // TODO: When we add a row, make sure the form data is cleared
                Object.keys(lastRow).map(key => {
                    const associatedConfig = config.find(x => x.ControlName === key)
                    const fieldName = getInputNameFromConfig(associatedConfig, key) 
                    const formName = `${accessor}[${lastRowIdx}].${fieldName}`

                    // hack for the id keys in dependent tables
                    if (formName.includes('ID') && !formName.includes('Select')) {
                        return setValue(formName.replace('ID', 'IDSelect'), null)   
                    }
                    return setValue(formName, null)
                })
            }
        }
    }, [data, submissionState, accessor, setValue, config])


    useEffect(() => {
        setTableData(prevTableData => {
            const newTableData = deepClone(prevTableData)
            newTableData[accessor] = data
            return newTableData
        })
    }, [data, accessor])
    
    const tableData = useMemo(() => data, [data])
    return (
        <SectionWrapper title={title} subtitle={subtitle}>
            <PageErrorText string={pageErrorText[accessor]} />
            <GeneralErrors />
            <div className="formTableWrapper">
                <EditableTable
                    columns={columns}
                    data={tableData}
                    setData={!viewOnly ? setData : null}
                    setControlledPageSize={updateControlledPageSize.bind(this, accessor)}
                    beforePageChange={beforePageChange}
                />
            </div>
        </SectionWrapper>
    )
}
