import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'

import withConfig from '../../../wrappers/withConfig'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import actions from '../../../../utils/submissions/actions'
import { UserContext } from '../../../wrappers/UserContext'
import toast from '../../../elem/Toast'

const TableContext = createContext(null)

const TableContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [loading, setLoading] = useState(true)
    const [uploads, setUploads] = useState(null)
    const { API_URL } = config
    const [selectedUploads, setSelectedUploads] = useState([])
    const [bulkComments, setBulkComments] = useState()
    const [displayBulkApproveModal, setDisplayBulkApproveModal] = useState(false)
    const [displayBulkDenyModal, setDisplayBulkDenyModal] = useState(false)
    const { user } = useContext(UserContext)

    // fetch uploads
    useEffect(() => {
        actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
    }, [])

    const resolveBulkModalState = useCallback((buttonAction, state) => {
        if (buttonAction === 'Approve')
            setDisplayBulkApproveModal(state)
        else 
            setDisplayBulkDenyModal(state)
    }, [displayBulkApproveModal, displayBulkDenyModal])
    // bulk submit
    const bulkSubmit = useCallback(action => {
        if (action === 'Deny' && !bulkComments) {
            toast({
                level: 'error',
                message:
                    `Comments are required for denied submissions.`,
                alert: true,
            })
            return
        }
    
        const selectedUploadIds = selectedUploads.map(x => x['Form ID'])
        actions.bulkSubmit(action, authenticatedFetch, API_URL, selectedUploadIds, bulkComments, user.profile.name, () => {
            // alert the user that upload was successful
            toast({
                level: 'info',
                message: `All selected forms have been ${action}submitted and can now be viewed in the Submitted Forms tab`,
                alert: true
            })
            // refetch table data
            actions.getTableData(authenticatedFetch, API_URL, setLoading, setUploads)
            setDisplayBulkApproveModal(false)
        })
    }, [selectedUploads, user, bulkComments])
    
    return (
        <TableContext.Provider value={{ 
            uploads,
            setUploads,
            selectedUploads,
            setSelectedUploads,
            loading,
            bulkSubmit,
            setBulkComments,
            displayBulkApproveModal,
            setDisplayBulkApproveModal,
            setDisplayBulkDenyModal,
            displayBulkDenyModal,
            resolveBulkModalState
        }}>
            {children}
        </TableContext.Provider>
    )
}

export { TableContext }
export default withConfig(TableContextProvider)
