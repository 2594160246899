import React from 'react'

export default ({
    selectedGrouping,
    setSelectedGrouping,
    displayGroupingWindowDropdown,
    colorClass,
    groupingList,
}) => {
    if (!displayGroupingWindowDropdown) {
        return null
    }
    
    return (
        <div className="analyteDropdownWrapper">
            {groupingList.map((option, idx) => {
                const active = selectedGrouping ? selectedGrouping.Code === option.Code : null
                return (
                    <div
                        key={`timeoption-${idx}`}
                        className={`analyteOptionWrapper ${
                            active ? 'activeOption ' + colorClass : ''
                        }`}
                        onClick={() => {
                            if (selectedGrouping 
                                && (selectedGrouping.Code === option.Code))
                                setSelectedGrouping(null)
                            else
                                setSelectedGrouping(option)
                        }}
                    >
                        <span className="analyteOptionText">
                            {option.CodeDescription}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}