import urls, { createSubmissionUrl } from '../constants/urls'
import toast from '../../components/elem/Toast'

const isValidFormType = (formType) => {
    return formType && Object.keys(formType).length
}

// upload
const create = (authFetch, API_URL, history, formType, agencyCode, username) => {
    authFetch(`${API_URL}/upload/create`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify({
            AgencyCode: agencyCode,
            UploadUser: username,
            FormType: formType.FormType,
        }),
    })
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            const submissionId = response.submissionId
            history.push(`${createSubmissionUrl(urls.form, formType.Link)}/${submissionId}`)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Submission: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const approve = (authFetch, API_URL, history, uploadId, comments) => {
    try {
        const commentsParam = comments ? comments : ''
        authFetch(
            `${API_URL}/upload/approve?uploadId=${uploadId}&comments=${commentsParam}`
        )
            .then(() => {
                history.push(urls.manageSampleResults)
            })
            .catch(e =>
                toast({
                    level: 'error',
                    message: `Submission Approval Error: ` + e.message,
                    alert: true,
                })
            )
    } catch {}
}

const reject = (authFetch, API_URL, history, uploadId, comments, username) => {
    try {
        authFetch(
            `${API_URL}/upload/reject?uploadId=${uploadId}&comments=${comments}&user=${username}`
        )
            .then(() => {
                history.push(urls.manageSampleResults)
            })
            .catch(e =>
                toast({
                    level: 'error',
                    message: `Submission Approval Error: ` + e.message,
                    alert: true,
                })
            )
    } catch {}
}

// submission ui
const getExistingSubmission = (authFetch, API_URL, uploadId, formType, after) => {
    authFetch(`${API_URL}/upload/${formType.APILink}/uploadData?uploadId=${uploadId}`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.uploadData)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Existing Submission Data: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getUploadChanges = (authFetch, API_URL, uploadId, formType, after) => {
    authFetch(`${API_URL}/upload/${formType.APILink}/uploadChanges?uploadId=${uploadId}`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.uploadChanges)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Existing Submission Data: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getSubmissionConfig = (authFetch, API_URL, formType, after) => {
    authFetch(`${API_URL}/upload/${formType.APILink}/uploadConfig`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.uploadConfig)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Submission Config: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getReviewText = (authFetch, API_URL, uploadId, after) => {
    authFetch(`${API_URL}/upload/getReviewText?uploadId=${uploadId}`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.reviewText)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Get Submission Review Text: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getProjectFacilityConfig = (authFetch, API_URL, after) => {
    authFetch(`${API_URL}/upload/project/projectFacilityConfig`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Project Facility Config:' +
                    (e.message ? e.message : 'Unable to fetch Project Facility config.'),
            })
        })
}

// edd
const getEDDConfig = (authFetch, API_URL, formType, after) => {
    authFetch(`${API_URL}/upload/${formType.APILink}/eddConfig`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.config)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'EDD Config:' +
                    (e.message ? e.message : 'Unable to fetch EDD config.'),
            })
        })
}

// forms in progress + submitted forms grids
const getTableData = (authFetch, API_URL, setLoading, after) => {
    setLoading(true)
    authFetch(`${API_URL}/upload`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Submissions: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
        .finally(() => setLoading(false))
}

const bulkSubmit = (
    uploadAction,
    authFetch,
    API_URL,
    uploadIds,
    bulkComments,
    username,
    after
) => {
    authFetch(`${API_URL}/upload/bulk${uploadAction}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify({
            UploadUser: username,
            UploadIds: uploadIds,
            Comments: bulkComments,
        }),
    })
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Bulk Submission: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
                alert: true,
            })
        })
}

// shared
const getAgencies = (authFetch, API_URL, after) => {
    authFetch(`${API_URL}/upload/agencies`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.agencies)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Submission Agencies: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getFormTypes = (authFetch, API_URL, after) => {
    authFetch(`${API_URL}/upload/formTypes`)
        .then(async response => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then(response => {
            after(response.formTypes)
        })
        .catch(e => {
            toast({
                level: 'error',
                message:
                    'Submission Form Types: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const out = {
    reject,
    approve,
    create,
    getExistingSubmission,
    getUploadChanges,
    getAgencies,
    getSubmissionConfig,
    getProjectFacilityConfig,
    getEDDConfig,
    getTableData,
    getReviewText,
    getFormTypes,
    isValidFormType,
    bulkSubmit
}

export default out
