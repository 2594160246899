import React, { useContext } from 'react'

import Filter from './Filter'
import List from './List'
import DataContextProvider from './DataContext'
import Panel from '../../../elem/panel/Panel'
import PressureHeader from './PressureHeader'
import { pressurePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import { AppStateContext } from '../AppStateContext'

const PressureVolumeExplorer = ({ width }) => {
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        promotedRecords,
        togglePromotedRecords,
    } = useContext(AppStateContext)

    const [isCollapsed, setCollapsed] = React.useState(false)
    const filterExpanded = filterState['pressure']
    const toggleFilter = updateFilterState.bind(this, 'pressure')

    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <PressureHeader
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords['pressure']}
                        togglePromotedRecords={togglePromotedRecords}
                        setCollapsed={setCollapsed}
                        isCollapsed={isCollapsed}
                    />
                     {
                        !isCollapsed && 
                    (<Filter
                        width={width}
                        filterExpanded={filterExpanded}
                        syncFilterState={syncFilterState}
                    />)}
                      {
                        !isCollapsed && 
                    (<List
                        promotedRecords={promotedRecords}
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                    />)}
                    
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default PressureVolumeExplorer
