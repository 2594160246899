import React from 'react'
import { FaSlidersH, FaThList, FaArrowAltCircleUp, FaRegArrowAltCircleUp, FaAngleUp, FaAngleDown  } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'

export default ({
    filterExpanded,
    toggleFilter,
    togglePromotedRecords,
    promotedRecords,
    setCollapsed,
    isCollapsed
}) => {
    const promotedRecordsFieldName = 'pressure'
    return (
        <PanelHeader extraClass={'pressurePanelHeader'}>
            <div className="panelHeaderText">Pressure-Volume Explorer</div>
            <div className="panelHeaderControls">
                <div className="panelHeaderIconWrapper">

                    {!filterExpanded && <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            promotedRecords
                                ? `Do not promote Selected Records`
                                : `Promote  Selected Records`
                        }`}
                        data-for={`pressure-promoted-records-toggle`}
                        onClick={() => togglePromotedRecords(!promotedRecords, promotedRecordsFieldName)}
                    >
                        {promotedRecords ? <FaArrowAltCircleUp /> : <FaRegArrowAltCircleUp />}
                        <Tooltip id={'pressure-promoted-records-toggle'} />
                    </div>}
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? `View Pressure-Volume List`
                                : `View Pressure-Volume Filter`
                        }`}
                        data-for={`pressure-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        {filterExpanded ? <FaThList /> : <FaSlidersH />}
                        <Tooltip id={'pressure-view-toggle'} />
                    </div>
                    <Tooltip
                        id={'pressure-export-button'}
                        className={'is-white'}
                    />
                    <div
                    className="panelHeaderIconDiv"
                    data-tip={`${
                        isCollapsed
                            ? `Expand Explorer`
                            : `Hide Explorer`
                    }`}
                    data-for={`pressure-panel-toggle`}
                    onClick={() =>
                        setCollapsed(!isCollapsed)
                    }
                >
                    {isCollapsed ? (
                        <FaAngleUp />
                    ) : (
                        <FaAngleDown />
                    )}
                    <Tooltip
                        id={`pressure-panel-toggle`}
                    />
                </div>
                </div>
            </div>
        </PanelHeader>
    )
}
