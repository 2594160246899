import React, { useCallback, useMemo, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import {
    usePagination,
    useTable,
} from 'react-table'

import { getEmptyColumnObject } from '../../../utils/submissions/table'

const EditableTable = ({ columns, data, setData, setControlledPageSize, beforePageChange }) => {
    const addRow = useCallback(() => {
        if (setData) {

            // update the data with an empty column object
            const columnObject = getEmptyColumnObject(columns)
            setData(old => {
                return [...old, columnObject]
            })
        }
    }, [setData])

    const canSetData = useMemo(() => (setData ? true : false), [setData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        { columns, data, initialState: { pageIndex: 0 }, autoResetPage: false, setData, addRow },
        usePagination
    )

    useEffect(() => {
        setControlledPageSize(pageSize)
    }, [pageSize])

    return (
        <div className="gridWrapper editableTableWrapper">
            <table
                {...getTableProps()}
                className="table is-narrow is-fullwidth editableTable"
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="is-size-7"
                                >
                                    <div className="explorerTableHeader icon-color-3">
                                        {column.render('Header')}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className={`tableFooter ${data.length < pageSize ? 'is-hidden' : ''}`}>
                <div className="buttons are-small">
                    <button
                        type="button"
                        className={`button is-link`}
                        onClick={() => beforePageChange(() => gotoPage(0))}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </button>{' '}
                    <button
                        type="button"
                        className={`button is-link`}
                        onClick={() => beforePageChange(() => previousPage())}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </button>{' '}
                    <button
                        type="button"
                        className={`button is-link`}
                        onClick={() => beforePageChange(() => nextPage())}
                        disabled={!canNextPage}
                    >
                        {'>'}
                    </button>{' '}
                    <button
                        type="button"
                        className={`button is-link`}
                        onClick={() => beforePageChange(() => gotoPage(pageCount - 1))}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </button>{' '}
                </div>
                <div className="resultsCount margin-left-sm">
                    <span className="margin-right-sm">
                        <strong>{data.length}</strong> Total Results
                    </span>
                    <span className="margin-right-sm">
                        | Page <strong>{pageIndex + 1}</strong> of{' '}
                        <strong>{pageOptions.length}</strong>
                    </span>
                </div>
                <div className="pageNavigator">
                    <span>| Go to page:</span>
                    <div className="field is-horizontal">
                        <div className="control">
                            <input
                                type="number"
                                className="input is-small margin-left-sm"
                                value={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0
                                    return beforePageChange(() => gotoPage(page))
                                }}
                                style={{ width: '100px' }}
                            />
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="control">
                            <div
                                className={`select is-small margin-left-sm carat-class-3`}
                            >
                                <select
                                    // className="select is-small margin-left-sm"
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {canSetData ? (
                <div className="newTableRowWrapper">
                    <button
                        type="button"
                        className="button is-small is-link"
                        onClick={() => addRow()}
                    >
                        <span>Add Row</span>
                        <span className="icon is-small">
                            <FaPlus />
                        </span>
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default EditableTable
