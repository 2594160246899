import React, { useContext } from 'react'

import AgencyConfirmation from '../AgencyConfirmation'
import SubmissionForm from './SubmissionForm'
import { DataContext } from '../DataContext'

export default () => {
    const { activeAgency, createUpload } = useContext(DataContext)

    return activeAgency ? <SubmissionForm /> : <AgencyConfirmation onSubmit={createUpload}/>
}
