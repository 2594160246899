import React, { useCallback, useContext, useMemo } from 'react'
import Modal from '../../../elem/Modal'
import toast from '../../../elem/Toast'

import { TableContext } from './TableContext'

const BulkSubmitButton = ({ action }) => {
    const { selectedUploads, bulkSubmit, setBulkComments, resolveBulkModalState, displayBulkApproveModal, displayBulkDenyModal } = useContext(TableContext)
    const allSelectedUploadsSubmitted = useMemo(() => selectedUploads.every(x => x.Status === 'Submitted'), [selectedUploads])
    const allSelectedUploadsValid = useMemo(() => selectedUploads.every(x => x.IsValid === 'Pass'), [selectedUploads])
    const isApprove = useMemo(() => action === 'Approve', [action])

    const onApproveDenyClick = useCallback(() => {
        if (!allSelectedUploadsSubmitted || (isApprove && !allSelectedUploadsValid && !allSelectedUploadsSubmitted)) {
            const approveActionMessage = `All selected forms must have Status of Submitted and Validation Status of Pass in order to approve`
            const denyActionMessage = `All selected forms must have Status of Submitted in order to deny`
            toast({
                level: 'error',
                message: isApprove ? approveActionMessage : denyActionMessage,
                alert: true,
            })
            return
        }
        resolveBulkModalState(action, true)
    }, [action, selectedUploads])

    const onSubmitClick = useCallback(() => {  
        if (!allSelectedUploadsValid) {
            toast({
                level: 'error',
                message:
                    `All selected forms must have Validation Status of Pass in order to submit`,
                alert: true,
            })
            return
        }
        bulkSubmit(action)
    }, [action, selectedUploads])

    const commentsField = (
        <div className="field is-horizontal ">
            <div className="field-label is-align-items-center">
                <label className="label">Comments:</label>
            </div>
            <div className="field-body field control">       
                <textarea id="txtArea" rows="10"
                    className="input is-fullwidth "
                    name={'Comments'}
                    onChange={e => setBulkComments(e.target.value)}
                ></textarea>
            </div>
        </div>
    )

    return (
        <div className="bulkSubmitWrapper">
                <Modal
                    display={isApprove ? displayBulkApproveModal : displayBulkDenyModal}
                    confirmAction={() => bulkSubmit(action)}
                    confirmText={`${action} Forms`}
                    denyAction={() => resolveBulkModalState(action, false)}
                    denyText={`Cancel`}
                    title={`${action} Forms`}
                >
                    {commentsField}
                </Modal>
            <button 
                className="button is-normal is-link bulkSubmitButton" 
                disabled={selectedUploads.length === 0} 
                onClick={() => action === 'Submit' ? onSubmitClick() : onApproveDenyClick(action)}
                >
                {action}
            </button>
        </div>
    )
}

export default BulkSubmitButton