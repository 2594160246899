import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const parseColumns = data => {
    if (data && data.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => ({
            Header: () => <div>{col}</div>,
            accessor: col,
            Cell: ({ cell }) => <div>{cell.value}</div>,
        }))
        return columns
    } else {
        return []
    }
}

const parseColumnsWithConfig = (data, config) => {
    if (data && data.length && config && config.length) {
        const exampleRow = data[0]
        const columns = Object.keys(exampleRow).map(col => {
            let columnName = col
            const configObj = config.find(x => x.ColumnName === col)
            if (configObj) {
                columnName = configObj.ColumnTitle
            }
            return {
                Header: () => <div>{columnName}</div>,
                accessor: col,
                Cell: ({ cell }) => {
                    let value = cell.value
                    if (configObj) {
                        if (configObj.Values) {
                            var matchingConfigValue = configObj.Values.filter(
                                x => x.code !== null
                            ).find(
                                x =>
                                    x.code === value ||
                                    x.code.toString() === value.toString()
                            )
                            
                            if (matchingConfigValue && matchingConfigValue.codedescription) {
                                value = matchingConfigValue.codedescription
                            }
                        }
                    }
                    return <div>{value}</div>
                },
            }
        })
        return columns
    } else {
        return []
    }
}

const getInProgressColumns = (data, deleteFunction) => {
    const columns = parseColumns(data).filter(
        x => x.accessor !== 'Link' && x.accessor !== 'IsValid'
    )
        return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            Cell: ({ cell }) => {
                return (
                    <div>
                        <Link
                            to={
                                cell.row.values.IsEDD
                                    ? `submissions/${cell.row.original.Link}/edd/${cell.row.original['Form ID']}`
                                    : `submissions/${cell.row.original.Link}/form/${cell.row.original['Form ID']}`
                            }
                        >
                            Edit
                        </Link>
                        {` | `}
                        <div
                            className="link inline-block"
                            onClick={() => deleteFunction(cell.row.original['Form ID'])}
                        >
                            Delete
                        </div>
                    </div>
                )
            },
        },
    ]
}

const getSubmittedColumns = (data, isReviewer) => {
    const columns = parseColumns(data).filter(x => x.accessor !== 'Link' && x.accessor !== 'IsValid')
    return [
        {
            Header: () => <div>Validation Status</div>,
            accessor: 'IsValid',
            Cell: ({ cell }) => {
                const isValid = cell.row.values.IsValid === 'Pass'
                return (
                    <div>
                        {isValid ? (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaCheck className="has-text-success" />
                                    </i>{' '}
                                </span>
                                <span>Pass </span>
                            </span>
                        ) : (
                            <span className="icon-text">
                                <span className="icon">
                                    <i className="icon">
                                        <FaTimes className="has-text-danger" />
                                    </i>{' '}
                                </span>
                                <span>Fail </span>
                            </span>
                        )}
                    </div>
                )
            },
        },
        ...columns,
        {
            Header: () => <div>Actions</div>,
            accessor: 'nothing',
            Cell: ({ cell }) => {
                return (
                    <div>
                        <Link
                            to={
                                cell.row.values['Is EDD?'] === 'Yes'
                                    ? `submissions/${cell.row.original.Link}/edd/view/${cell.row.original['Form ID']}`
                                    : `submissions/${cell.row.original.Link}/form/view/${cell.row.original['Form ID']}`
                            }
                        >
                            View
                        </Link>
                        {isReviewer && cell.row.values.Status === 'Submitted' && (
                            <>
                                {` | `}
                                <Link
                                    to={
                                        cell.row.values['Is EDD?'] === 'Yes'
                                            ? `submissions/${cell.row.original.Link}/edd/review/${cell.row.original['Form ID']}`
                                            : `submissions/${cell.row.original.Link}/form/review/${cell.row.original['Form ID']}`
                                    }
                                >
                                    Review
                                </Link>
                            </>
                        )}
                    </div>
                )
            },
        },
    ]
}

const getEmptyColumnObject = columns => {
    return columns.reduce((acc, curr) => {
        const field = curr.accessor
        return {
            ...acc,
            [field]: null,
        }
    }, {})
}

export {
    getInProgressColumns,
    getSubmittedColumns,
    parseColumns,
    getEmptyColumnObject,
    parseColumnsWithConfig,
}
