import React from 'react'
import { useLocation } from 'react-router-dom'

import urls, { createSubmissionUrl } from '../../../../utils/constants/urls'
import Spinner from '../../Spinner'

const TableBody = ({ getTableBodyProps, page, selectedItems, selectedItemColumn, prepareRow, onClick, loading }) => {
    const location = useLocation()
    return (
        <tbody {...getTableBodyProps()}>
            {loading ? (
                <tr>
                    <td colSpan="100%">
                        <Spinner />
                    </td>
                </tr>
            ) : (
                page.map((row, i) => {
                    prepareRow(row)
                    const isHighlighted = selectedItems ? selectedItems.includes(
                        row.original[selectedItemColumn]
                    ) : false
                    return (
                        <tr
                            onClick={(e) => {
                                e.stopPropagation()
                                if (location.pathname === urls.home || location.pathname.includes(createSubmissionUrl(urls.form, 'facility'))) {
                                    onClick(row, isHighlighted, e, page)
                                }
                            }}
                            {...row.getRowProps({
                                className: isHighlighted ? 'is-selected highlightedRow' : '',
                            })}
                        >
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })
            )}
        </tbody>
    )
}

export default TableBody