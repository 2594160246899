
const generateDateParams = (params) => {
    const dates = (params.sample && params.sample.SimpleSampleDateValue) ? decodeURIComponent(params.sample.SimpleSampleDateValue).split(',') : []
    if (!(dates.length)) {
        return [null, null]
    }
    const startDate = dates[0]
    const endDate = dates.length > 1 ? dates[1] : null
    return [startDate, endDate]
}

const generatePressureDateParams = (params) => {
    const startDates = (params.pressure && params.pressure.MonitorReqStartDateValue) 
        ? decodeURIComponent(params.pressure.MonitorReqStartDateValue).split(',') 
        : []
    const monitorReqStartDate = {
        startDate: startDates[0],
        endDate: startDates.length > 1 ? startDates[1] : null
    }

    const endDates = (params.pressure && params.pressure.MonitorReqEndDateValue) 
        ? decodeURIComponent(params.pressure.MonitorReqEndDateValue).split(',') 
        : []
    const monitorReqEndDate = {
        startDate: endDates[0],
        endDate: endDates.length > 1 ? endDates[1] : null
    }
    return {
        monitorReqStartDate,
        monitorReqEndDate,
    }
}

const roundToFourDigits = (number) => {
    return Number(Math.round(Number( number.toPrecision(4)) + 'e3') + 'e-3')
}

export { generateDateParams, roundToFourDigits, generatePressureDateParams }