import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { FaInfoCircle, FaAsterisk, FaTimes } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'

import urls from '../constants/urls'
import { AppStateContext } from '../../components/features/explorer/AppStateContext'
import { getFieldNameFromColumn } from '../stringUtils'
import Tooltip from '../../components/elem/Tooltip'
import {
    uploadInputSelector,
    uploadValidate,
    FieldComponent,
    getInputType,
} from '../form/formFieldParser'
import cloneDeep from 'lodash.clonedeep'
import { convertFieldDuplicateString } from '../submissions/values'

const getDescriptionValue = (value, filterItem) => {
    if (!value) {
        return null
    }
    
    const matchingValueObject = value
        ? filterItem.Values.find(
              x =>
                  x.code === value.toString() ||
                  x.code === value ||
                  x.code === parseInt(value)
          )
        : null
    if (matchingValueObject) {
        return matchingValueObject.codedescription
    } else {
        return value
    }
}

const validateDate = (date, format) => {
    const formatted = dayjs(date, format).format(format)
    return formatted === date
}

const formatValue = (value, roundIfApplicable) => {
    // try to cast value as date
    try {
        const isDate = validateDate(value, 'YYYY-MM-DDTHH:mm:ss')
        if (isDate) {
            return dayjs(value)
                .format('YYYY/MM/DD')
                .toString()
        }
        // if the value is null, simply return it
        if (value === null) {
            return value
        }

        // if the value is not a number, return it
        if (Number.isNaN(Number(value))) {
            return value
        }

        // if the value is a number, and roundIfApplicable,
        // round it to one digits
        if (roundIfApplicable) {
            return Math.round((value + Number.EPSILON) * 10) / 10
        }

        return value
    } catch {
        // if caught, its not a date value
        return value
    }
}

const WellLinkCell = ({ cell, filterFields, idColumn }) => {
    const location = useLocation()
    const context = useContext(AppStateContext)
    // if we're not on the single page app,
    // these links do nothing
    if (!(location.pathname === urls.home)) {
        return <DescriptionCell cell={cell} filterFields={filterFields} />
    }

    const { updateDetailState } = context

    const idValue = idColumn && cell.row.values[idColumn] ? cell.row.values[idColumn] : cell.value
    return (
        <div className="descriptionCell linkCell">
            <span
                className=""
                onClick={e => {
                    updateDetailState('well', {
                        visible: true,
                        facilityID: idValue,
                    })
                    e.stopPropagation()
                }}
            >
                {formatValue(cell.value)}
            </span>
        </div>
    )
}

const DescriptionCell = ({ cell, filterFields }) => {
    const controlName = cell.column.id
    const initialValue = cell.value
    const filterItem = filterFields.find(x => x.ControlName === controlName)
    const hasFilterFields = filterItem && !!filterItem.Values
    const value = hasFilterFields
        ? getDescriptionValue(initialValue, filterItem)
        : initialValue
    return <div className="descriptionCell">{formatValue(value)}</div>
}

const HeaderCell = ({ width, columnName, filterFields, indicateRequired }) => {
    let filterItem = filterFields.find(x => x.ColumnName === columnName || x.ControlName === columnName)
    const hasFilterInfo = filterItem && !!filterItem.ColumnDescription
    const required = filterItem && indicateRequired && !!filterItem.Required
    return (
        <div className="headerCell" style={width ? {width: width} : null}>
            <div className="headerCellText">
                <span>
                    {getFieldNameFromColumn(columnName, filterFields)}
                </span>
                {required ? (
                    <span className="requiredStar has-text-danger">
                        {<FaAsterisk />}
                    </span>
                ) : null}{' '}
            </div>
            {hasFilterInfo ? (
                <div className="headerCellIcon">
                    <span
                        data-tip={`${filterItem.ColumnDescription}`}
                        data-for={`table-${columnName}`}
                        className="icon is-left"
                    >
                        <FaInfoCircle />
                        <Tooltip id={`table-${columnName}`} />
                    </span>
                </div>
            ) : null}
        </div>
    )
}

const parseFormColumns = (data, filterFields, linkColumn, idColumn) => {
    if (data.length) {
        const exampleData = data[0]
        // get the columns that are not hidden with colDevControls table
        const visibleFields = filterFields
            .filter(x => x.DisplayInTable)
            .map(x => x.ControlName)
        const columns = Object.keys(exampleData)
            .filter(fieldName => {
                const fieldFilterItem = filterFields.find(
                    x => x.ColumnName === fieldName
                )
                return (
                    fieldFilterItem &&
                    visibleFields.includes(fieldFilterItem.ControlName)
                )
            })
            .reduce((acc, cur) => {
                return acc.concat([
                    {
                        Header: (
                            <HeaderCell
                                columnName={cur}
                                filterFields={filterFields}
                            />
                        ),
                        accessor: cur,
                        Cell: ({ cell }) =>
                            cell.column.id === linkColumn ? (
                                <WellLinkCell
                                    idColumn={idColumn}
                                    cell={cell}
                                    filterFields={filterFields}
                                />
                            ) : (
                                <DescriptionCell
                                    cell={cell}
                                    filterFields={filterFields}
                                />
                            ),
                    },
                ])
            }, [])
        return columns
    }
    return []
}

const parseUploadColumns = (
    data,
    uploadConfig,
    deleteFunction,
    submissionState,
    viewOnly
) => {
    // get the columns that are not hidden with colDevControls table
    let returnColumns = []
    const visibleFields = uploadConfig
        .filter(x => x.DisplayInTable)
        .map(x => x.ControlName)
    const columns = visibleFields.reduce((acc, cur, idx) => {
        const uploadField = cloneDeep(uploadConfig.find(x => x.ControlName === cur))
        return acc.concat([
            {
                Header: (
                    <HeaderCell width={uploadField && uploadField.ColumnWidth ? uploadField.ColumnWidth : null} columnName={uploadField.ColumnName} filterFields={uploadConfig} indicateRequired={true} />
                ),
                accessor: cur,
                Cell: ({ cell }) => {
                    if (viewOnly) {
                        uploadField.ViewOnly = true
                    }
                    const fieldProps = {
                        field: uploadField,
                        formInputSelector: uploadInputSelector,
                        getInputType,
                        formWidth: '',
                        hrColorClass: '',
                        formName: null,
                        validate: uploadValidate,
                        idx,
                        rowIdx: cell.row.index,
                        displayLabel: false,
                    }
                    return <FieldComponent {...fieldProps} />
                },
            },
        ])
    }, [])
    const deleteColumn = {
        Header: <HeaderCell columnName={''} filterFields={[]} />,
        accessor: 'idx',
        Cell: ({ cell }) => (
            <div>
                <div
                    onClick={() => deleteFunction(cell.row.index)}
                    className="link padding-top-sm"
                >
                    <FaTimes />
                </div>
            </div>
        ),
    }
    if (!viewOnly) {
        returnColumns = [...columns, deleteColumn]      
    } else {
        returnColumns = columns
    }

    if (uploadConfig && uploadConfig.length) {
        const exampleConfig = uploadConfig[0]
        const requires = exampleConfig.Requires
        if (requires && submissionState[requires] && Array.isArray(submissionState[requires])) {
            const tableName = requires[0].toUpperCase() +
            requires.slice(1)
            const columnTitle = `${tableName} Name`
            const valueAccessor = `Upload${tableName}ID`
            const descriptionAccessor = `${tableName}Name`
            const fieldDuplicateAccessor = `IsFieldDuplicate`
            const values = submissionState[requires].length
                ? submissionState[requires].map(x => ({
                      code: x[valueAccessor],
                      codedescription: `${x[descriptionAccessor]}${!!x[fieldDuplicateAccessor] ? convertFieldDuplicateString(x[fieldDuplicateAccessor]) : ''}`,
                      active: true
                  }))
                : null

            const field = {
                ...exampleConfig,
                ColumnDescription: null,
                Description: null,
                ColumnName: valueAccessor,
                ColumnTitle: columnTitle,
                CompareItems: '=',
                ControlName: valueAccessor,
                ControlType: 'Select',
                DataType: 'Int',
                MaxLength: null,
                MaxValue: null,
                MinValue: null,
                Prompt: `${columnTitle}:`,
                Required: true,
                Requires: requires,
                SectionType: 'Table',
                Values: values,
                ViewOnly: viewOnly
            }
            const dependentIdColumn = {
                Header: (
                    <HeaderCell width={175} columnName={valueAccessor} filterFields={[field]} indicateRequired={true} />
                ),
                accessor: valueAccessor,
                Cell: ({ cell }) => {
                    const fieldProps = {
                        field,
                        formInputSelector: uploadInputSelector,
                        getInputType,
                        formWidth: '',
                        hrColorClass: '',
                        formName: null,
                        validate: uploadValidate,
                        rowIdx: cell.row.index,
                        displayLabel: false,
                    }
                    return <FieldComponent {...fieldProps} />
                },
            }

            returnColumns = [dependentIdColumn, ...returnColumns]
        }
    }
    return returnColumns
}

export default parseFormColumns
export { getDescriptionValue, formatValue, parseUploadColumns }
