import React, {
    useCallback,
    useContext,
    useMemo,
} from 'react'
import get from 'lodash.get'

import { FaAsterisk, FaInfoCircle } from 'react-icons/fa'
import Tooltip from '../../../elem/Tooltip'
import { DataContext } from '../DataContext'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import {
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    isChanged,
} from '../../../../utils/submissions/values'
import FieldErrors from './FieldErrors'

const Input = ({
    fieldName,
    name,
    type,
    helper,
    register,
    registerParams,
    errors,
    minValue,
    maxValue,
    maxLength,
    dataAccessor,
    required,
    displayLabel,
    rowIdx,
    disabled,
    watch,
    extraClass
}) => {
    const {
        submissionState,
        activePanel,
        viewOnly,
        errorState,
        stampLatLong,
    } = useContext(DataContext)
    const c = useContext(ExistingDataContext)
    const uploadChanges = c ? c.uploadChanges : null

    // const apiError = errorState[activePanel] ? errorState[activePanel].find(x => x.fieldName === name) :  null
    const filterName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}`

    // stamp fields based on lat / long entry on blur
    const onBlur = useCallback(() => {
        if (name === 'Latitude' || name === 'Longitude') {
            stampLatLong()
        } else {
            return null
        }
    }, [name])

    const changed = useMemo(
        () =>
            isChanged(
                uploadChanges,
                dataAccessor,
                name,
                rowIdx,
                submissionState
            ),
        [uploadChanges, dataAccessor, name, rowIdx, submissionState]
    )

    // construct input props
    const inputProps = {
        name: filterName,
        type: type,
        ref: register(registerParams),
    }

    const formError =
        errors && get(errors, inputProps.name)
            ? get(errors, inputProps.name)
            : null

    const warning = useMemo(() => {
        return getWarningFromErrorState(errorState, name, rowIdx, dataAccessor)
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const apiError = useMemo(() => {
        return getApiErrorFromErrorState(
            errorState,
            name,
            rowIdx,
            dataAccessor,
            formError
        )
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    if (type === 'number') {
        if (minValue) {
            inputProps.min = minValue
        }
        if (maxValue) {
            inputProps.max = maxValue
        }
    }
    if (maxLength) {
        inputProps.maxLength = maxLength
    }

    return (
        <div
            className={`column ${
                displayLabel ? 'is-one-third' : ''
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field is-horizontal">
                    {displayLabel && (
                        <div className="field-label is-small">
                            <label className="label">
                                <span>{fieldName}</span>
                                {required ? (
                                    <span className="requiredStar has-text-danger">
                                        {<FaAsterisk />}
                                    </span>
                                ) : null}{' '}
                            </label>
                        </div>
                    )}
                    <div className="field-body">
                        <div className="field is-expanded">
                            <div className="field has-addons">
                                <div
                                    className={`control is-expanded ${
                                        helper ? 'has-icons-left' : ''
                                    }`}
                                    data-for={inputProps.name}
                                    data-tip={watch(inputProps.name)}
                                >
                                    {!(viewOnly || disabled) ? (
                                        <input
                                            {...inputProps}
                                            name={inputProps.name}
                                            ref={register(registerParams)}
                                            className={`input is-small formInput 
                                                    ${
                                                        changed
                                                            ? 'is-changed'
                                                            : ''
                                                    }
                                                     ${extraClass}
                                                    `}
                                            // defaultValue={initialValue}
                                            onBlur={() => onBlur()}
                                        />
                                    ) : (
                                        <input
                                            disabled
                                            {...inputProps}
                                            // name={inputProps.name}
                                            className={`input is-small ${
                                                changed ? 'is-changed' : ''
                                            }`}
                                            // value={initialValue}
                                        />
                                    )}
                                    <Tooltip
                                        id={inputProps.name}
                                        className={`tooltip-2`}
                                    />
                                    {helper && (
                                        <span
                                            data-tip={`${helper}`}
                                            data-for={`${inputProps.name}-helper`}
                                            className="icon is-left formHelper"
                                        >
                                            <FaInfoCircle />
                                            <Tooltip
                                                id={`${inputProps.name}-helper`}
                                                className={''}
                                            />
                                        </span>
                                    )}
                                    <FieldErrors
                                        formError={formError}
                                        warning={warning}
                                        apiError={apiError}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Input
