import React, { useContext } from 'react'

// import LastSampleDetails from './LastSampleDetails'
import { DataContext } from './DataContext'
import { formatValue } from '../../../../utils/table/parseColumns'

const WellDetails = () => {
    const { detailData, filterData, visibleTab } = useContext(DataContext)
    if (
        !(
            detailData &&
            detailData.well &&
            filterData &&
            filterData.well &&
            visibleTab === 'well'
        )
    ) {
        return null
    }
    const formFields = filterData.well
    const data = detailData.well
    const fieldGroups = [...new Set(formFields.map(x => x.GroupName))]
    return (
        <>
            <div className="columns is-multiline is-variable is-1 detailContentWrapper">
                {fieldGroups.map((fieldGroup, fieldGroupIdx) => {
                    const groupedFields = formFields.filter(
                        x => x.GroupName === fieldGroup
                    )
                    return (
                        <div
                            key={`fieldGroup-${fieldGroupIdx}`}
                            className="column is-half-desktop is-half-tablet is-full-mobile detailsDataWrapper"
                        >
                            <div className="detailsDataHeader">
                                {fieldGroup}
                            </div>
                            <div className="detailsData">
                                {groupedFields.map((field, idx) => {
                                    const fieldLabel = field.Prompt
                                    const fieldColumn = field.ColumnName
                                    const fieldValue = data[fieldColumn]
                                    return (
                                        <div
                                            key={`field-${idx}`}
                                            className="field is-horizontal"
                                        >
                                            <div className="label is-size-7">
                                                {fieldLabel}
                                            </div>
                                            <div
                                                className="value margin-left-sm is-size-7"
                                                dangerouslySetInnerHTML={{
                                                    __html: formatValue(
                                                        fieldValue
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <LastSampleDetails /> */}
        </>
    )
}

export default WellDetails
